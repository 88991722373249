
















import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})
export default class PropertiesForRent extends Mixins(View) {
  private listingFilter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
    ],
    Categories: [
      ListingCategory.ResidentialRental,
    ],
    PageSize: 9,
    OrderByStatements: [
      ListingOrderBy.SoldDateDesc,
    ],
  });

  handleFilter(filter: ListingFilter) {
    this.listingFilter = filter;
  }
}
